<template>
  <div class="loading page">
    <div class="wrap">
        <img src="/img/lottery/love.png" class='love' alt="">
        <div class='tip'>매칭 데이터 검색중...</div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            timer: null
        }
    },
    destroyed() {
        clearTimeout(this.timer)
        this.timer = null
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this.$http({
                method: 'get',
                url: 'lottery_list'
            }).then(res=>{
                if(res.data.length !== 0) {
                    if(this.timer !== null) {
                        clearTimeout(this.timer)
                        this.timer = null
                    }
                    this.timer = setTimeout(() => {
                        this.$router.replace(`/Lottery?key=game1&id=${res.data[0].id}`)
                    }, 2000)
                }
            })
        }
    }
}
</script>

<style lang='scss' scoped>
    .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .love {
            width: 300px;
            height: 300px;
            animation: love 3s ease-in-out infinite;
            transform-origin: center center;
        }

        .tip {
            margin-top: 50px;
            color: #fff;
            font-size: 30px;
        }
    }

    .loading {
        min-height: 100vh;
        background-image: url('/img/lottery/loading.jpg');
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: floatBackground 10s linear infinite;
    }

    @keyframes floatBackground {
        0% {
            background-position: 0% 0%;
        }
        50% {
            background-position: 100% 100%;
        }
        100% {
            background-position: 0% 0%;
        }
    }

    @keyframes love {
        0% {
            transform: scale(1); 
        }
        50% {
            transform: scale(1.3); 
        }
        100% {
            transform: scale(1); 
        }
    }
</style>

<style lang="scss" scoped>
@media (min-width: 700px) {
    .page {
        max-width: 500px !important;
        margin: 0 auto !important;
    }

    .wrap .love {
        width: 150px !important;
        height: 150px !important;
    }

    .wrap .tip {
        font-size: 20px !important;
        margin-top: 20px !important;
    }

    .loading {
        background-attachment: local !important;
    }
}
</style>