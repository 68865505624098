<template>
  <div class="bg-container page">
    <img class="bg-img" src="img/login/login-bg.png">
    <div class="bg-wrapper">
      <div class="register">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar>
        <div class="wrapper">
          <div class="logo-container">
            <div class="logo-wrapper">
              <img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !==undefined ?this.$store.getters.getBaseInfo.ico:''">
            </div>
          </div>
          <div class="title">회원등록</div>
          <div class="loginForm">
            <van-field v-model="username" left-icon="contact" :maxlength="10" clearable input-align="left" class="input"  placeholder="로그인 아이디 ( 영문 또는 숫자 4~10자 )를 입력해주세요." />
            <van-field
                v-model="password"
                type="text"
                input-align="left"
                class="input"
                :maxlength="10"
                placeholder="로그인 비밀번호 ( 영문 또는 숫자 4~10자 )를 입력해주세요.">
              <!-- <template slot="right-icon">
                <van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType"/>
              </template> -->
              <template slot="left-icon">
                  <van-icon class="iconfont" class-prefix="icon" name="mima" size='24px' style="margin-right: 2px" />
                </template>
            </van-field>
            <van-field v-model="code"  clearable input-align="left" class="input"  :placeholder="$t('qing-shu-ru-yao-qing-ma')">
              <template slot="left-icon">
                  <van-icon class="iconfont" type="number" class-prefix="icon" name="yaoqingma" size='24px' style="margin-right: 2px" />
                </template>
            </van-field>
            <!-- <div class="agreement">
              <van-checkbox v-model="checked" />
              <span class="agreement-text">{{ $t('wo-yi-jing-zhi-xiao-bing-tong-yi-kai-hu-xie-yi-ge-xiang-tiao-yue') }}</span>
            </div> -->
            <van-button class="login-btn" type="primary" size="normal" @click="doRegister()">회원등록</van-button>
            <!-- <div class="reset-text">
                <span @click="toLogin()">{{ $t('yi-you-zhang-hu-ma-shang-deng-lu') }}</span>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: '',
      password: this.inputValue,
      passwordType: 'password',
    };
  },
  methods: {
    toLogin() {
      this.$router.push('Login')
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
    doRegister() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast.fail('아이디를 입력해주세요!');
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast.fail('비밀번호를 입력해주세요!');
        return false;
      }
      if(this.code === "" || this.code === null || this.code === undefined){
        this.$toast.fail('초대코드를 입력해주세요!');
        return false;
      }
      // if(!this.checked){
      //   this.$toast.fail(this.$t('qing-gou-xuan-xia-fang-kai-hu-xie-yi'));
      //   return false;
      // }
      this.$http({
        method: 'post',
        data:{
          username:this.username,
          password:this.password,
          code:this.code
        },
        url: 'member_register'
      }).then(res=>{
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data)
            this.$router.push('Mine')
          }else {
            this.$toast.fail(res.msg);
          }
      })
    }
  },
  created() {
    if(localStorage.getItem('token')){
      return window.history.back();
    }
  }
};
</script>

<style lang="less" scoped>
::v-deep .van-field__right-icon {
  color: #fff;
}

/deep/ .van-cell {
  background-color: transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #fff !important;

  &::after {
    border-bottom: none !important;
  }
}
::v-deep .van-field__control {
  color: #fff !important;
}
</style>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.register{
  height: 100%;
}
.nav-bar {
	background: transparent !important;
}
.bg-container .bg-wrapper .register .nav-bar{
  background: 0 0
}

.bg-container {
  background: #000;
}
.register .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container{
  margin: 0 auto;
  width: 45%;
}
.logo-container .logo-wrapper{
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.register .wrapper .title{
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.register .wrapper .loginForm{
  padding: 60px;
}
.register .wrapper .loginForm .input{
  padding: 10px 0;
  margin-top: 35px;
  border-radius: 50px;
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  color: #fff;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.register .wrapper .loginForm .reset-text{
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
}
.register .wrapper .loginForm .register-text{
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .register-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
}
.register .wrapper .loginForm .login-btn{
  margin-top: 30px;
  width: 100%;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  // background-color: #7e5678;
  background-color: #91608a;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}
.register .wrapper .loginForm .agreement{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.register .wrapper .loginForm .agreement .agreement-text{
  margin-left: 10px;
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  line-height: 30px;
}
::v-deep .agreement .van-icon {
  font-size: 30px;
}
::v-deep .agreement .van-checkbox__icon {
  font-size: 38px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}
</style>
<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    max-width: 500px !important;
    margin: 0 auto !important; 
  }

  .nav-bar {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-size: 20px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 25px !important;
  }

  * {
    font-size: 16px !important;
  }

  .register .wrapper .title {
    margin: 20px 0 !important;
    line-height: normal !important;
  }

  .register .wrapper .loginForm {
    padding: 20px !important;
  }

  .van-cell {
    line-height: normal !important;
  }

  ::v-deep .van-icon {
    font-size: 26px !important;
  }

  .iconfont.icon.icon-mima {
    font-size: 26px !important;
  }

  .register .wrapper .loginForm .input {
    margin-top: 20px !important;
  }

  .register .wrapper .loginForm .reset-text {
    margin: 20px !important;
  }

  .register .wrapper .loginForm .login-btn {
    margin-top: 30 !important;
    height: auto !important;
    padding: 15px 0 !important;
  }

  .van-field__control {
    line-height: 25px !important;
  }

  .register .wrapper .loginForm .agreement {
    margin-top: 20px !important;
  }

  ::v-deep .agreement .van-checkbox__icon {
    font-size: 30px !important;
  }

  ::v-deep .van-checkbox__icon .van-icon {
    font-size: 16px !important;
  }

  .register .wrapper .loginForm .agreement .agreement-text {
    line-height: normal !important;
    margin-left: 10px !important;
  }

  ::v-deep .van-checkbox__icon.van-checkbox__icon--round {
    display: flex !important;
    align-items: center !important;
  }

  ::v-deep .icon-yaoqingma {
    font-size: 22px !important;
  }

  ::v-deep .van-field__body {
    height: 100% !important;
  }
}
</style>