<template>
    <van-nav-bar
        :title="title"
        :left-text="leftText"
        :right-text="rightText"
        :left-arrow="showArrow"
        :border="border"
        :fixed="fixed"
        :z-index="999"
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
        <template slot="right" v-if='!rightText'>
            <slot name="right"></slot>
        </template>
    </van-nav-bar>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        leftText: {
            type: String,
            default: ''
        },
        rightText: {
            type: String,
            default: ''
        },
        showArrow: {
            type: Boolean,
            default: false
        },
        fixed: {
            type: Boolean,
            default: false
        },
        border: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    methods: {
        onClickLeft() {
            this.$emit('leftClick')
        },
        onClickRight() {
            this.$emit('leftClick')
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__title.van-ellipsis {
    color: #000;
    font-size: 26px;
}

::v-deep .van-nav-bar__content {
    height: 78px;
}
</style>