<template>
	<van-tabbar :z-index="99999" v-if="show" v-model="active" active-color="#7e5678" :border="true" inactive-color="#979799">
		<van-tabbar-item replace to="/Home">
			<span>홈 페이지</span>
			<template #icon="props">
				<img :src="props.active ? '/img/footer/home.jpg' : '/img/footer/home_no.jpg'" :alt="$t('shou-ye')" />
			</template>
		</van-tabbar-item>
		<!-- <van-tabbar-item replace to="/Loading">
			<span>{{ $t('yu-yue') }}</span>
			<template #icon="props">
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key !== 2" />
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" style="height: 4rem;" v-show="key === 2" />
			</template>
		</van-tabbar-item> -->
		<van-tabbar-item replace to="/Loading">
			<span>Endless Love</span>
			<template #icon="props">
				<img src="/img/logo_1.png" style="height: 4rem;" class="tui" :alt="$t('yu-yue')" />
			</template>
		</van-tabbar-item>
		<!-- <van-tabbar-item replace to="/Video">
			<span>{{ $t('shi-pin') }}</span>
			<template #icon="props">
				<img :src="props.active ? '/img/footer/video.jpg' : '/img/footer/video_no.jpg'" :alt="$t('shi-pin')" />
			</template>
		</van-tabbar-item> -->
		<van-tabbar-item replace to="/Mine">
			<span>마이페이지</span>
			<template #icon="props">
				<img :src="props.active ? '/img/footer/my.jpg' : '/img/footer/my_no.jpg'" :alt="$t('wo-de')" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0,
			item: [
				{
					router: '/Home',
					title: this.$t('shou-ye'),
					icon: {
						active: '/img/footer/home.jpg',
						noactive: '/img/footer/home_no.jpg'
					}
				},
				{
					router: '/Game',
					title: this.$t('yu-yue'),
					icon: {
						active: '/img/footer/yuyue.jpg',
						noactive: '/img/footer/yuyue_no.jpg'
					}
				},
				// {
				// 	router: '/Choose',
				// 	title: '',
				// 	icon: {
				// 		active: '/img/footer/beauty.png',
				// 		noactive: '/img/footer/beauty.png'
				// 	}
				// },
				// {
				// 	router: '/Video',
				// 	title: this.$t('shi-pin'),
				// 	icon: {
				// 		active: '/img/footer/video.jpg',
				// 		noactive: '/img/footer/video_no.jpg'
				// 	}
				// },
				{
					router: '/Mine',
					title: this.$t('wo-de'),
					icon: {
						active: '/img/footer/my.jpg',
						noactive: '/img/footer/my_no.jpg'
					}
				}
			]
		};
	},
	watch: {
		$route(to) {
			if (to.name == 'home') {
				this.active = 0;
				this.show = true;
			} else if (to.name == 'game') {
				this.active = 1;
				this.show = true;
			} else if (to.name == 'choose') {
				this.active = 5;
				this.show = true;
			} else if (to.name == 'video') {
				this.active = 6;
				this.show = true;
			} else if (to.name == 'mine') {
				this.active = 2;
				this.show = true;
			} else if(to.name == 'Loading') {
				this.active = 1;
				this.show = true;
			} else {
				this.show = false;
			}
		}
	},
	created() {
		if (this.$route.name == 'home') {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == 'game') {
			this.active = 1;
			this.show = true;
		} else if (this.$route.name == 'choose') {
			this.active = 5;
			this.show = true;
		} else if (this.$route.name == 'video') {
			this.active = 6;
			this.show = true;
		} else if (this.$route.name == 'mine') {
			this.active = 2;
			this.show = true;
		} else {
			this.show = false;
		}
	}
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 75px;
.van-tabbar {
	height: @tabbar-height;
}
.van-tabbar-item__icon img {
	height: @tabbar-img;
}

.van-tabbar-item {
	font-size: 26px;
}
.tui {
	width: 4rem;
	margin-top: -10.333vw;
	background-color: white;
	border-radius: 50%;
	border: 10px solid white;
	z-index: 10;
}
[class*='van-hairline']::after {
	border: none !important;
}


@media (min-width: 700px) {
	
.van-tabbar-item__icon img.tui {
	height: 65px !important;
	width: 65px !important;
	border: 10px solid #fff !important;
}
	.van-tabbar {
		height: 70px !important;
	}

	.van-tabbar-item__icon img {
		height: 40px !important;
	}

	.van-tabbar-item {
		font-size: 16px !important;
	}

	.van-tabbar--fixed {
		max-width: 500px !important;
		left: 50% !important;
		transform: translateX(-50%) !important;
		border-top: 1px solid #ccc !important;
	}

	.tui {
		margin-top: -32px !important;
	}
}
</style>
