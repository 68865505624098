<template>
    <div class="container page">
      <van-nav-bar :title="$t('chong-zhi')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
        <template #right>
            <van-icon name="wap-home-o" color="#fff" @click="home()"/>
        </template>
      </van-nav-bar>
      <div class="main">
        <van-cell-group>
            <van-field v-model="money" type="digit" :placeholder="$t('qing-shu-ru-chong-zhi-jin-e')" />
        </van-cell-group>
        
        <div class="tip">{{ $t('chong-zhi-qian-qing-lian-xi-zai-xian-ke-fu-huo-qu-chong-zhi-fang-shi') }}</div>
        <div class="tip">{{ $t('dao-zhang-zhang-hao-userinfoname-0', [userInfo.username || '']) }}</div>
        <!-- <div class="type">
            <span>支付方式：</span>
            <select v-model='type'>
                <option :value="item.value" v-for='item in typeList' :key='item.id'>{{item.title}}</option>
            </select>
        </div> -->
        <el-divider></el-divider>
        <div class="btn_wrap">
            <div class="btn" @click="save">{{ $t('li-ji-chong-zhi') }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Recharge",
    data() {
      return {
        money: '',
        userInfo:{},
        type: 1,
        typeList: [{
            id: 1,
            title: 'KRW',
            value: 1
        }]
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
      home() {
        this.$router.push('/home')
      },
      save(){
        if(this.money){
            if(this.money < 0) {
                this.$toast(this.$t('qing-shu-ru-zheng-que-jin-e'));
                return
            }
            if(this.money == 0) {
                this.$toast(this.$t('jinebu-neng-wei-ling'));
                return
            }

            this.$toast(this.$t('qing-lian-xi-ke-fu-chong-zhi'));
        } else {
            this.$toast(this.$t('qing-shu-ru-chong-zhi-jin-e-0'));
        }
        
      },
      getUserInfo(){
        this.$http({
          method: 'get',
          url: 'user_info'
        }).then(res=>{
          if(res.code === 200){
            this.userInfo = res.data;
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
    },
    created() {
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.getUserInfo();
      }
    }
  };
  </script>
  
  <style lang='less' scoped>
    .btn_wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .btn {
            width: 90%;
            background: #a57f88;
            color: #fff;
            border-radius: 100px;
            text-align: center;
            padding: 25px 0; 
        }
    }
    
    ::v-deep .el-divider {
        background-color: #a57f88;
        height: 3px;
    }
    .tip {
        margin-left: 0px;
        color: #666666;
        font-size: 3.867vw;  
        margin-top: 30px;
    }
    .type {
        color: #666666;
        font-size: 3.867vw;  
        margin-top: 30px;
        display: flex;
        align-items: center;
    }
    .container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        .main {
            flex: 1;
            background: #fff;
            padding: 20px;
            box-sizing: border-box;
            padding-top: 50px;
        }
    }

  @import "../../assets/css/base.css";
  .van-cell {
    font-size: 30px;
    line-height: 80px;
    box-shadow: 0 0 10px #0000001a inset;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: #f5f5f5;
    color: #000;
  }
  
  ::v-deep .van-field__label {
    width: 120px !important;
  }
  </style>
  <style lang="scss" scoped>
  @media (min-width: 700px) {
    .page {
      max-width: 500px !important;
      margin: 0 auto !important; 
    }
  
    .nav-bar {
      height: 50px !important;
    }
  
    ::v-deep .van-nav-bar__content {
      height: 50px !important;
    }
  
    ::v-deep .van-nav-bar__title {
      font-size: 20px !important;
    }
  
    .van-nav-bar .van-icon {
      font-size: 25px !important;
    }
  
    * {
      font-size: 16px !important;
    }
  
    .container .main {
      padding: 20px !important;
    }

    .van-cell {
      line-height: 50px !important; 
    }

    .tip {
      margin-top: 20px !important;
    }

    .el-divider {
      height: 1px;
      margin: 20px 0 !important;
    }

    .btn_wrap .btn {
      padding: 15px 0 !important;
    }
  }
  </style>