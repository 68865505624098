<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('ti-xian-ji-lu')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('xia-la-ji-ke-shua-xin')" :loosing-text="$t('shi-fang-ji-ke-shua-xin')" :loading-text="$t('jia-zai-zhong')">
          <van-empty v-if="list.length === 0" :description="$t('shu-ju-wei-kong-0')" />
          <div v-else class="item_list" v-for="(v,key) in list" :key="key">
            <div class="topInfo">
              <span v-if="v.status === 2" style="color: #07c160">{{ $t('shen-he-cheng-gong') }}</span>
              <span v-if='v.status == 1'>{{ $t('dai-shen-he') }}</span>
              <span v-if='v.status == 3'>{{ $t('shen-he-shi-bai') }}</span>
              <span style="color: #000 !important">{{ $t('jinevmoney', [Number(v.money).toFixed(0)]) }}</span>
            </div>
            <div class="desc">
              <span>{{ $t('shuo-ming-vdesc', [v.desc]) }}</span>
            </div>
            <div class="time">
              <span>{{ $t('ti-jiao-shi-jian-vcreatetime', [v.create_time]) }}</span>
            </div>
            <div class="time">
              <!-- <span>{{ $t('shen-he-shi-jian-vupdatetime', [v.update_time]) }}</span> -->
            </div>
          </div>
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[]
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('shua-xin-cheng-gong-0'));
        this.isLoading = false;
      }, 500);
    },
    getUserWithdrawList(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_list'
      }).then(res=>{
        if(res.code === 200){
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserWithdrawList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}

</style>
<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    max-width: 500px !important;
    margin: 0 auto !important; 
  }

  .nav-bar {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-size: 20px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 25px !important;
  }

  * {
    font-size: 16px !important;
  }

  ::v-deep .van-empty__image {
    width: 200px !important; 
    height: 200px !important; 
  }

  ::v-deep .van-empty__description {
    margin-top: 20px !important;
    font-size: 20px !important;
    line-height: normal !important;
  }
  
  .container .main {
    padding: 0 20px !important;
  }
  
  .item_list {
    padding: 20px !important;
    line-height: 30px !important;
    margin: 0 !important;
    margin-top: 20px !important;
  }
}
</style>