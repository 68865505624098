<template>
  <div id="app">
      <router-view></router-view>
      <Footer/>
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status:0
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    getBaseInfo(){
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res=>{
        if(!localStorage.getItem('token')){
          this.$router.push({path:'/Login'})
        }
        this.$store.commit('setBaseInfoValue', res.data);
      })
    },
    isMobileDevice() {
      const rect = document.body.getBoundingClientRect()
      return rect.width - 1 < 992
    },
    handleResize() {
      if (this.isMobileDevice()) {
          this.$store.dispatch('setMobile', true)
        } else {
          this.$store.dispatch('setMobile', false)
      }
    } 
  },
  created(){
    this.getBaseInfo();
    // this.handleResize()
    // window.addEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss">
body .van-toast {
  font-size: 30px;
  padding: 4px 15px;
  line-height: 50px;
  max-width: 50%;
  min-width: 250px;
  text-align: center;
}
body .van-toast .van-toast__icon {
  font-size: 60px;
  font-weight: bold;
  margin-top: 15px;
}
*, :after, :before {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none !important;
}

::v-deep .van-loading__spinner {
  width: 25px !important;
  height: 25px !important;
}
</style>
