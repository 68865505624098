<template>
  <div class="container page">
    <van-nav-bar :title="$t('xiu-gai-zhen-shi-xing-ming')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="save()">{{ $t('bao-cun') }}</span>
      </template>
    </van-nav-bar>
 
    <van-cell-group>
      <van-field v-model="name" :readonly="userInfo.name !== '' && userInfo.name !== null" :label="$t('xing-ming')" :placeholder="$t('qing-shu-ru-zhen-shi-xing-ming')" />
    </van-cell-group>
    <p>{{ $t('wei-le-nin-zhang-hu-an-quan-zhen-shi-xing-ming-xu-yao-yu-bang-ding-yin-hang-ka-xing-ming-yi-zhi') }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name:"",
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    save(){
      if(this.userInfo.name){
        this.$toast(this.$t('qing-wu-zhong-fu-she-zhi'));
        return true;
      }
      if(this.name === "" || this.name === null || this.name === undefined){
        this.$toast.fail(this.$t('qing-shu-ru-xing-ming'));
        return false;
      }
      this.$http({
        method: 'get',
        data:{name:this.name},
        url: 'user_set_name'
      }).then(res=>{
        if(res.code === 200){
          this.getUserInfo();
          this.name = this.userInfo.name;
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 30px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}

::v-deep .van-field__label {
  width: 120px !important;
}
</style>
<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    max-width: 500px !important;
    margin: 0 auto !important; 
  }

  .nav-bar {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-size: 20px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 25px !important;
  }

  * {
    font-size: 16px !important;
  }

  .van-cell {
    line-height: normal !important;
  }

  ::v-deep .van-field__label {
    width: fit-content !important;
  }
}
</style>