<template>
	<div class="page">
		<van-nav-bar title="" class="nav-bar" fixed>
			<template #title>
				<van-icon name="/img/logo_center.png" color="#fff" />
			</template>
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<video class="video" :src="xuanfeidata.video_url" controls></video>
		<div class="box">
			<div class="info" style="margin: 20px 0">
				<div>
					<span class='label'> ID： </span>
					<span class='value'> {{ xuanfeidata.id }} </span>
				</div>
				<div>
					<span class='label'> 
						<van-icon class="iconfont" class-prefix="icon" name="gerenxinxi" color="#ff3c59" size='24px' style="margin-right: 2px;" />
						{{ $t('xiang-xi-di-zhi') }} 
					</span>
					<span class='value' style="text-decoration: underline;" @click="yuyue"> {{ $t('yi-yin-cang-lian-xi-ke-fu-ji-huo-ke-jian') }} </span>
				</div>
				<div>
					<span class='label'>
						<van-icon class="iconfont" class-prefix="icon" name="lianxifangshi" color="#ff3c59" size='24px' style="margin-right: 2px;" /> 
						{{ $t('lian-xi-fang-shi') }} 
					</span>
					<span class='value' style="text-decoration: underline;" @click="yuyue"> {{ $t('yi-yin-cang-lian-xi-ke-fu-ji-huo-ke-jian-0') }} </span>
				</div>
			</div>
			<div class="info" style="margin: 20px 0">
				<div>
					<span class='label'> {{ $t('mei-nv-xin-xi') }} </span>
					<span class='value'>
						{{ xuanfeidata && xuanfeidata.xuanfei_name || ''}}
					</span>
				</div>
				<div class="rate">
					<span>{{ $t('yan-zhi-da-fen') }}</span>
					<el-rate
						:value="5"
						disabled
						:show-text="false"
						style="transform: translateY(-4px);"
					>
					</el-rate>
				</div>
				<div class="rate">
					<span>{{ $t('mei-nv-zhi-liang') }}</span>
					<el-rate
						:value="5"
						disabled
						:show-text="false"
						style="transform: translateY(-4px);"
					>
					</el-rate>
				</div>
			</div>

			<van-image v-for="(v, k) in xuanfeidata.img_url" :key="k" width="98%" class="image_person" fit="contain" height="100%" :src="v">
				<template v-slot:loading>
					<van-loading type="circular"/>
				</template>
			</van-image>

			<!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :width="xuanfeidata && xuanfeidata.img_url && xuanfeidata.img_url.length >= 3 ? 300 : 'auto'">
				<van-swipe-item v-for="(v, k) in xuanfeidata.img_url" :key="k">
					<van-image width="98%" class="image_person" fit="contain" height="100%" :src="v">
						<template v-slot:loading>
							<van-loading type="circular"/>
						</template>
					</van-image>
				</van-swipe-item>
			</van-swipe> -->
		</div>
		<div class="bottom_btn">
			<van-button :round="true" @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">{{ $t('yu-yue') }}</van-button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.nav-bar {
	background: #fff !important;
}
.my-swipe {
	min-height: 392px;
}
.image_person {
	height: 292px;
}
.bottom_btn {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;

	.button {
		height: 80px;
		width: 80%;
		background: linear-gradient(to right, #352835, #bf7db5) !important;
	}
}

::v-deep .van-swipe__indicator {
	width: 20px !important;
	height: 20px !important;
	margin-right: 10px;
}
::v-deep .van-icon__image {
	width: 5em !important;
}
.info {
	background: #fff7f7;
	border-radius: 10px;
	padding: 20px;
	margin-top: 20px;
	text-align: left;
	border: 5px solid #ffaab7;

	div {
		padding: 10px 0;
		display: flex;
		align-items: flex-start;

		.label {
			white-space: nowrap;
			display: flex;
			align-items: center;
		}

		.value {
			color: #999;
		}
	}

	::v-deep .el-rate__icon {
		font-size: 35px;
	}
}

::v-deep .van-nav-bar__title {
	color: #000;
}

::v-deep .van-icon.van-icon-arrow-left {
	color: #000 !important;
	font-size: 34px;
}

::v-deep .van-nav-bar__title {
	display: flex;
	align-items: center;
}
</style>

<script>
export default {
	data() {
		return {
			xuanfeidata: {
				img_url: ['', '']
			}
		};
	},
	methods: {
		back() {
			this.$router.back();
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				console.log(res.data)
				this.xuanfeidata = res.data;
			});
		},
		yuyue() {
			this.$toast(this.$t('qing-lian-xi-gu-wen-huo-jie-dai-yuan'));
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style>
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
}
.name {
	font-size: 1.125rem;
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
.video {
	width: 100%;
	margin-top: 44px;
}
</style>
<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    max-width: 500px !important;
    margin: 0 auto !important; 
  }

  .nav-bar {
    height: 50px !important;
	max-width: 500px !important;
	left: 50% !important;
	transform: translateX(-50%);
  }

  .video {
	margin-top: 50px !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-size: 20px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 25px !important;
  }

  * {
    font-size: 16px !important;
  }

  .van-icon__image {
	height: 25px !important;
	width: 100px !important;
  }

  .bottom_btn {
	max-width: 500px !important;
	left: 50% !important;
	transform: translateX(-50%);
  }

  .bottom_btn .button {
	height: auto !important;
	padding: 15px 0 !important;
  }

  .info {
	padding: 15px !important;
	border-radius: 10px !important;
	border: 2px solid #ffaab7 !important;
  }

  .info div {
	padding: 10px 0 !important
  }

  .info ::v-deep .el-rate__icon {
	font-size: 25px !important;
	transform: translateY(-8px) !important;
  }
  
  .info .rate {
	padding: 0 !important;
  }
}
</style>