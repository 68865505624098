<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('xuan-fei')" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab :title="$t('cheng-shi-da-quan')">
					<div class="card">{{ $t('ben-ping-tai-quan-wang-zui-zhen-shi-wai-wei-shang-wu-ban-you-tong-cheng-ji-qing-wei-bao-zheng-mei-ge-yong-hu-de-ge-ren-yin-si-ke-hu-jin-xian-tong-guo-lian-xi-jie-dai-yuan-huo-ping-tai-zi-shen-hui-yuan-shi-ming-tui-jian-cai-ke-jia-ru') }}</div>
					<div class="address">
						<van-row type="flex" v-for="(val, key) in addlist" :key="key">
							<van-col span="6" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{v.name}}</van-col>
						</van-row>
					</div>
				</van-tab>
				<van-tab :title="$t('liu-cheng-jia-ge')">
					<div class="card">{{ $t('ben-ping-tai-quan-wang-zui-zhen-shi-wai-wei-shang-wu-ban-you-tong-cheng-ji-qing-wei-bao-zheng-mei-ge-yong-hu-de-ge-ren-yin-si-ke-hu-jin-xian-tong-guo-lian-xi-jie-dai-yuan-huo-ping-tai-zi-shen-hui-yuan-shi-ming-tui-jian-cai-ke-jia-ru-0') }}</div>
					<div class="rig-box">
						<p class="rig-title">{{ $t('you-na-xie-zi-yuan') }}</p>
						<p class="rig-content">{{ $t('wang-hong-mo-te-kong-jie-nen-mo-da-xue-sheng-zhi-you-nin-xiang-bu-dao-mei-you-ben-ping-tai-ban-bu-dao') }}</p>
						<p class="rig-title">{{ $t('fu-wu-fan-wei') }}</p>
						<p class="rig-content">{{ $t('tong-cheng-mian-fei-yue-pao-ren-yi-di-dian-quan-guo-kong-jiang-guo-nei-yi-er-xian-cheng-shi-dang-di-jun-you-san-xian-cheng-shi-ye-ke-tong-guo-lian-xi-jie-dai-yuan-yu-yue-an-pai') }}</p>
						<p class="rig-content">{{ $t('ben-ping-tai-quan-wang-zui-zhen-shi-wai-wei-shang-wu-ban-you-tong-cheng-ji-qing-wei-bao-zheng-mei-ge-yong-hu-de-ge-ren-yin-si-ke-hu-jin-xian-tong-guo-lian-xi-jie-dai-yuan-huo-ping-tai-zi-shen-hui-yuan-shi-ming-tui-jian-cai-ke-jia-ru-1') }}</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: []
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: #a57f88;
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
</style>
<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    max-width: 500px !important;
    margin: 0 auto !important; 
  }

  .nav-bar {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-size: 20px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 25px !important;
  }

  * {
    font-size: 16px !important;
  }

  ::v-deep .van-tabs--line .van-tabs__wrap {
	height: 50px !important; 
  }

  ::v-deep .van-tab {
	font-size: 16px !important;
  }

  ::v-deep .van-row--flex {
	line-height: 40px !important;
	cursor: pointer;
	height: auto !important;
  }
}
</style>