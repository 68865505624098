<template>
  <div class="mine page">
    <nav-bar title="">
      <template #right>
        <van-icon name="/img/tuichu.png" v-show="isLogin" size="27" @click="loginout()" color="#fff"/>
      </template>
    </nav-bar>
    <div class="page-bg"></div>
    <div class="wrapper">
      <van-pull-refresh v-model='isLoading' @refresh="onRefresh" :pulling-text="$t('xia-la-ji-ke-shua-xin')" :loosing-text="$t('shi-fang-ji-ke-shua-xin')" :loading-text="$t('jia-zai-zhong')">
         <div class="header">
           <div class="user-wrapper" @click="doLogin()">
             <van-image round class="user_img" src="/img/logo_1.png">
               <template v-slot:loading>
                 <van-loading type="spinner"/>
               </template>
             </van-image>
             <div class="login-content">
               <p class="login-btn" >{{this.userInfo.username}}님</p>
               <!-- <p class="login-label">{{this.userInfo.ip}}</p> -->
             </div>
             <!-- <div>
              <van-icon name="setting-o" size="27" @click="showSetting()" color="#fff"/>
             </div> -->
           </div>
         </div>
         <div class="content">
            <div class="finance center_wrap" v-if="!isLogin" @click="handleGoLogin">
              로그인 / 회원등록
            </div>
            <div class="finance" v-else>
              <div class="finance-item"  @click="doPay()">
                <van-icon class="icon" style="" name="peer-pay" />
                <span class="text">계좌등록</span>
              </div>
              <div class="line"></div>
              <div class="finance-item"  @click="doWithdrawal()">
                <van-icon class="icon" name="idcard" />
                <span class="text">환급신청</span>
              </div>
            </div>
            <div v-if="this.userInfo.money && isLogin" class="wallet">
                <div class="part-1 van-hairline--bottom">
                  <p class="flex-1 font-28 font-primary-color">내 포인트</p>
                  <!-- <span class="font-28 font-gray" @click="exit()">{{ $t('xiang-qing') }}</span> -->
                  <!-- <van-icon class="font-gray" style="font-size: 17px" name="arrow" /> -->
                </div>
                <div class="part-2">
                  <p class="balance van-ellipsis">{{Number(this.userInfo.money).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</p>
                  <span class="font-28 font-gray">원</span>
                  <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
                </div>
            </div>
            <div class="list_wrap" v-if='isLogin' :style="{ marginTop: menu_top +'px'}">
              <!-- <div class='list_item' @click="$router.push({path:'/Personalreport'});"> -->
              <div class='list_item' @click="$router.push({path:'/SetLoginPassword'});">
                <div class="left_i">
                  <van-icon class="icon" name="img/mine/yaoshi.png" size="30px"/>
                  <!-- <span>{{ $t('ge-ren-bao-biao') }}</span> -->
                  <span>{{ $t('deng-lu-mi-ma') }}</span>
                </div>
                <van-icon class="icon" name="arrow" color="#ccc" />
              </div>
              <div class='list_item' @click="$router.push({path:'/GameRecord'});">
                <div class="left_i">
                  <van-icon class="icon" name="img/mine/shuju.png" size="30px"/>
                  <span>데이터 기록</span>
                </div>
                <van-icon class="icon" name="arrow" color='#ccc' />
              </div>
              <!--  @click="exit()" -->
              <div class='list_item'>
                <div class="left_i">
                  <van-icon class="icon" name="img/mine/mingxi.svg" size="30px"/>
                  <span>{{ $t('zhang-hu-ming-xi') }}</span>
                </div>
                <van-icon class="icon" name="arrow" color='#ccc' />
              </div>
              <!--  @click="$router.push({path:'/Infomation'});" -->
              <div class='list_item'>
                <div class="left_i">
                  <van-icon class="icon" name="img/mine/user.svg" size="30px"/>
                  <span>{{ $t('ge-ren-zhong-xin') }}</span>
                </div>
                <van-icon class="icon" name="arrow" color='#ccc' />
              </div>
              <!-- <div class='list_item' @click="toNotice()">
                <div class="left_i">
                  <van-icon class="icon" name="img/mine/gonggao.svg" size="30px"/>
                  <span>{{ $t('xin-xi-gong-gao') }}</span>
                </div>
                <van-icon class="icon" name="arrow" color='#ccc' />
              </div>
              <div class='list_item' @click="toService()">
                <div class="left_i">
                  <van-icon class="icon" name="img/mine/kefu_1.svg" size="30px"/>
                  <span>{{ $t('zai-xian-ke-fu') }}</span>
                </div>
                <van-icon class="icon" name="arrow" color='#ccc' />
              </div> -->
            </div>
         </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import NavBar from '../../common/NavBar.vue'
export default {
  components: { NavBar },
  data() {
    return {
      userInfo:{
      },
      menu_top:40,
      isLoading: false,
    };
  },
  computed: {
    isLogin() {
      return !!localStorage.getItem('token')
    }
  },
  methods: {
    loginout(){
        localStorage.clear()
        this.$router.push({path:'/Login'});
    },
    handleGoLogin() {
      this.$router.push({path:'/Login'})
    },
    refresh(){
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
          this.getUserInfo();
          this.$toast(this.$t('shua-xin-cheng-gong'));
        }else{
          this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    exit(){
      this.$toast(this.$t('qing-wan-cheng-ren-wu-dan-hou-jin-ru'));
    },
    showSetting() {
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Setting'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    toNotice(){
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Notice'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
            this.getUserInfo();
            this.$toast(this.$t('shua-xin-cheng-gong-0'));
        }else{
            this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    doLogin(){
        if(localStorage.getItem('token')){
           
        }else {
            this.$router.push({path:'/Login'})
        }
    },
    doPay(){
      this.$router.push({path:'/Setbank'});
      // this.$router.push('/Recharge')
    },
    doWithdrawal(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
          if(res.data.is_bank){
            this.$router.push("withdraw");
          }else {
            // this.$router.push("Setbank");
            this.$toast.fail('계좌등록을 해주시길바랍니다.');
          }
      })
    },
    toService(){
      if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
      }else {
        this.$toast.fail(this.$t('gong-neng-yi-jin-yong'));
      }
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast(this.$t('zhang-hao-xia-xian'));
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }else {
      this.userInfo.username = this.$t('deng-lu-zhu-ce');
      this.userInfo.ip = this.$t('deng-lu-ke-xiang-shou-geng-duo-fu-wu');
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style lang="scss" scoped>
.list_wrap {
  background: #fff;
  border-radius: 10px;
  padding: 0 20px;
  .list_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    padding: 25px 0;
    border-bottom: 1px solid #eeeeee;

    &:last-child {
      border-bottom: none;
    }

    .left_i {
      display: flex;
      align-items: center;

      ::v-deep .van-icon {
        transform: translateY(2px);
      }

      span {
        margin-left: 10px;
      }
    }
  }
}
</style>



<style scoped lang='scss'>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
}
.mine{
  position: relative;
  bottom: 10px;
  background: linear-gradient(to bottom, #352835, #bf7db5);
}
.mine .wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 65px;
  box-sizing: border-box;
}
.nav-bar{
  background: transparent;
}
.mine .header{
  background: transparent;
  padding-bottom: 100px;
}
::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.van-nav-bar {
  background: transparent;
  margin-top: 10px;
}
.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}
.mine .header .user-wrapper{
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;

}
.mine .user_img{
  height: 130px;
  width: 130px;
}
::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}
::v-deep .van-image__error-icon {
  font-size: 70px;
}
.mine  .header .user-wrapper .login-content{
  flex: 1;
  margin-left: 30px;
}
.mine  .header .user-wrapper .login-content .login-btn{
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
}
.mine .header .user-wrapper .login-content .login-label{
  margin-top: -13px;
  font-size: 28px;
  color: hsla(0,0%,100%,.6);
}
.mine .page-bg{
  height: 500px;
  background: transparent;
}
.mine .content{
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  padding-bottom: 150px !important;
  /* background-color: #f2f2f5; */
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;

  &.center_wrap {
    justify-content: center;
  }
}
.mine .wrapper .content .finance .line{
  width: 3px;
  height: 40px;
  background-color: #ccc;
}
.mine .wrapper .content .finance .finance-item{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mine .wrapper .content .finance .finance-item .text{
  margin-left: 30px;
  font-size: 30px;
  color: #000;
  font-weight: 500;
}
.mine .wrapper .content .finance .finance-item .icon{
  font-size: 50px;
}
.mine .wrapper .content .menu{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .menu .menu-item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}
.mine .wrapper .content .menu .menu-item .menu-item-label{
  font-size: 30px;
  color: #868686;
  font-weight: 500;
}
.mine .wrapper .content .menu .menu-item .menu-item-icon{
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}
.mine .wrapper .content .wallet{
  margin-top: 80px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .wallet .part-1{
  display: flex;
  align-items: center;
  height: 100px;
}
.mine .wrapper .content .wallet .font-primary-color{
  color: #000;
}
.font-gray {
  color: #868686;
}
.mine .wrapper .content .wallet .part-2{
  display: flex;
  align-items: center;
  height: 150px;
}
.mine .wrapper .content .wallet .part-2 .balance{
  flex: 1;
  font-size: 60px;
  color: #7e5678;
  font-weight: 700;
}
.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.mine .wrapper .content .wallet .part-2 .refresh-btn{
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #e6c3a1;
}
</style>
<style lang="scss" scoped>
@media (min-width: 700px) {
  .page {
    width: 500px;
    margin: 0 auto;
  }

  .user-wrapper {
    margin: 0 20px !important;
  }

  .mine .header {
    padding-bottom: 0 !important;
  }

  .mine .wrapper .content .wallet {
    margin-top: 20px !important;
    border-radius: 10px !important;
  }

  .list_wrap .list_item {
    padding: 10px 0 !important;
  }

  .list_wrap {
    padding: 0 20px !important;
    border-radius: 10px !important;
  }

  .mine .wrapper .content .wallet .part-2 .refresh-btn {
    width: 30px !important;
    height: 30px !important;
    font-size: 20px !important;
  }

  .mine .page-bg {
    height: auto !important;
  }

  .mine .content {
    padding-bottom: 0 !important;
    min-height: 0 !important;
  }

  .mine .wrapper .content .finance .finance-item .text {
    margin-left: 10px !important;
  }

  .mine .wrapper .content .wallet .van-hairline--bottom::after {
    border-bottom-width: 2px !important;
  }

  .mine .wrapper .content .wallet .part-2 {
    height: 85px !important;
  }

  .mine .wrapper .content .wallet {
    padding: 0 20px !important;
  }

  .mine .wrapper .content .wallet .part-1 {
    height: auto !important;
    padding: 20px 0 !important;
  }

  .mine .wrapper .content .finance {
    height: auto !important;
    border-radius: 10px !important;
    padding: 15px 0;
  }

  .mine .wrapper .content .finance .line {
    width: 2px !important;
    height: 30px !important;
  }

  .mine .wrapper .content .finance {
    margin-top: 20px;
    position: relative !important;
    left: auto !important;
    top: 0 !important;
    right: auto !important;
  }

  p {
    margin: 0 !important;
  }

  .mine .content {
    padding: 0 20px !important;
  }

  .mine .header .user-wrapper .login-content .login-label{
    margin-top: 10px !important;
  }

  .van-nav-bar {
    margin-top: 0 !important;
  }

  .mine .header .user-wrapper .login-content {
    margin-left: 20px !important; 
  }

  .mine .header .user-wrapper .login-content .login-btn {
    line-height: auto !important;
  }

  .mine .wrapper {
    padding-top: 50px !important;
  }
  
  ::v-deep .van-nav-bar__content {
    height: 50px !important;
  }
  
  * {
    font-size: 16px !important;
  }

  .user_img {
    width: 80px !important;
    height: 80px !important;
  }

  .van-nav-bar .van-icon {
    font-size: 26px !important;
  }

  .van-icon__image {
    width: 30px !important;
    height: 30px !important;
  }

  ::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
    font-size: 20px !important;
  } 

  .mine .wrapper .content .wallet .part-2 .balance {
    font-size: 40px !important;
  }

  ::v-deep .van-loading__spinner {
    width: 25px !important;
    height: 25px !important;
  }

  .login-btn{
    font-size: 30px !important;
  }

  .font-gray {
    font-size: 26px !important;
  }
}
</style>