import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import './plugins'
import VueI18n from 'vue-i18n';
import en from './locales/en'; // 英文翻译
import zh from './locales/zh'; // 中文翻译
import ja from './locales/ja'; // 日语翻译
import ko from './locales/ko'; // 韩语翻译
import zhTW from './locales/zh-TW'; // 韩语翻译
import { Lazyload } from 'vant';

Vue.use(Lazyload);
Vue.use(VueI18n)
const messages = {
  en: en,
  zh: zh,
  ja: ja,
  ko: ko,
  'zh-TW': zhTW
}

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'ko', // 默认语言
  messages, 
});

import 'video.js/dist/video-js.css'

Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
